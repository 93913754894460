@import "https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap";
@import "https://fonts.googleapis.com/css2?family=Lilita+One&display=swap";
#caroselandcardcontainer {
  margin-top: 90px;
}

.carousel {
  height: 10px;
}

.pricelistimage {
  width: 200px;
  height: 160px;
}

.Cardcontainer {
  margin: 0 10px;
}

.categorycontainer {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 130px;
  display: flex;
}

.categroyimage {
  width: 300px;
  height: 200px !important;
}

.categoryname {
  color: #000;
  padding-left: 45%;
  font-family: Lilita One, sans-serif;
  text-decoration: none;
}

.navbar.navbar-expand-lg.bg-body-tertiary {
  z-index: 1000;
  width: 100%;
  height: 10%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #e9967a !important;
}

.navbar.navbar-expand-lg.bg-body-tertiary .dropdown-menu, .navbar.navbar-expand-lg.bg-body-tertiary .nav-item a, .navbar.navbar-expand-lg.bg-body-tertiary .nav-item a:hover {
  color: solid black;
  background-color: #e9967a;
}

.linkheader {
  color: solid black;
  font-family: Lilita One, sans-serif;
  font-size: x-large;
  text-decoration: none;
}

.linkheaderftcnt {
  color: #f0f8ff;
  text-decoration: none;
}

#categoory {
  margin-left: 50%;
  font-family: Bungee Spice, sans-serif;
  font-size: xx-large;
  font-style: italic;
  font-weight: 400;
  margin-top: 380px !important;
}

@media (width <= 480px) and (height <= 960px) {
  .categorycontainer {
    margin-top: -170px;
  }

  #categoory {
    justify-content: center;
    justify-self: center;
    margin-left: 28%;
    margin-top: 108px !important;
  }

  .Cardcontainer {
    margin: 0 25px;
  }

  .categroyimage {
    width: 9em;
  }

  .pricelistimage {
    width: 150px;
    height: 160px;
  }
}
/*# sourceMappingURL=index.b08deeb9.css.map */
